import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled, thd, up, css } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';

import VideoEmbed from '../../components/VideoEmbed/VideoEmbed';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import LoadAsync from '_platform/src/utils/LoadAsync';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';

import homeLogo from './images/LogoHeaderHomePage@2x.png';
import homeBg from './images/Home-bg.jpg';

import swoosh from '../../theme/SwooshCircle@2x.png';

import saputoImage from './images/Home-SaputoFoodService-Logo@2x.png';
import devondaleImage from './images/Home-DevondaleLogo@2x.png';
import cheerImage from './images/Home-CheerLogo@2x.png';
import cabooltureImage from './images/Home-CabooltureLogo@2x.png';

const StatementProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementProvider" */ '../StatementProvider/StatementProvider'
  )
);
const StatementLoyalty = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementLoyalty" */ '../../components/StatementLoyalty/StatementLoyalty'
  )
);

const Container = styled.div``;

const AlternateContainer = styled.div`
  background-image: url(${homeBg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: #213c71;

  ${up(
    'lg',
    css`
      background-size: contain;
    `
  )}

  padding: 2em 0 0 0;
  text-align: center;

  .homeLogo {
    height: 106px;
  }

  h1 {
    text-transform: uppercase;
    font-size: 40px;
  }
  p {
    line-height: 1.5;
  }
`;

const VideoContainer = styled.div`
  position: relative;

  :before {
    content: '';
    background-color: #213c71;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
  }
`;

const H2 = styled.h2`
  background-image: url(${swoosh});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 130px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StatementStyles = styled.div`
  border-bottom: 1px ${thd('secondary', '#203C70')} solid;
`;

const ProductRangeList = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: 40px;
  ${up(
    'md',
    css`
      flex-direction: row;
    `
  )}

  a {
    display: inline-block;
  }

  li {
    margin: 10px;
  }

  img {
    max-height: 100px;
    width: auto;
  }
`;

const HomePage = ({ settings }) => {
  return (
    <Container>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <AlternateContainer>
          <Grid>
            <Row justifyContent="center">
              <Col md={8} className="text--white spacer--bottom">
                <img src={homeLogo} alt="" className="homeLogo" />
                <h1>Welcome to Saputo Select</h1>
                <p>
                  As a valued partner, you have been selected to participate in
                  this exciting program. The aim of this initiative is to
                  partner closely with you to elevate your growth and reward
                  your success.
                </p>
                <p>
                  We're excited to embark on this journey with you, bringing you
                  tailored rewards, special pricing and select perks on our
                  trusted brands like Devondale, Cheer & Caboolture. Together,
                  let's create a partnership that fosters growth and new
                  opportunities.
                </p>
              </Col>
            </Row>
          </Grid>
        </AlternateContainer>
        <VideoContainer>
          <Grid>
            <Row justifyContent="center">
              <Col md={8} className="text--white">
                <VideoEmbed
                  videoId="dtKDL5YbsI0"
                  title="YouTube video: Introducing Saputo Select - Member"
                />
              </Col>
            </Row>
          </Grid>
        </VideoContainer>
        <Grid>
          <Row justifyContent="center">
            <Col md={8}>
              <H2>My activity</H2>
              <StatementStyles>
                <StatementProvider
                  fetch={[
                    {
                      endpoint: '/Statement/v1/Loyalty',
                      section: 'loyalty',
                    },
                    {
                      endpoint: '/Statement/v1/Saputo/Monthly',
                      section: 'monthly',
                    },
                    {
                      endpoint: '/Statement/v1/Saputo/Target',
                      section: 'target',
                    },
                  ]}
                  render={({ statement }) =>
                    !statement || !statement.data ? (
                      <LoadingAnimation /> // Loader instead of `null`?
                    ) : (
                      <StatementLoyalty
                        isHomePageView
                        statementHideClaimDetails
                        statement={statement.data}
                      />
                    )
                  }
                />
              </StatementStyles>
            </Col>
          </Row>
        </Grid>

        <Grid>
          <Row justifyContent="center">
            <Col md={10}>
              <H2>View our Product Range</H2>
              <ProductRangeList>
                <li>
                  <a
                    href="https://www.saputofoodservice.com.au/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={saputoImage}
                      alt="Saputo Foodservice"
                      className="spacer--around--small"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.saputofoodservice.com.au/en/products/all-products?filter=Devondale"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={devondaleImage}
                      alt="Devondale Australian Dairy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.saputofoodservice.com.au/en/products/all-products?filter=CHEER"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={cheerImage}
                      alt="Cheer"
                      className="spacer--around--small"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.saputofoodservice.com.au/en/products/all-products?filter=Caboolture"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={cabooltureImage} alt="Caboolture" />
                  </a>
                </li>
              </ProductRangeList>
            </Col>
          </Row>
        </Grid>
      </PrivateComponent>
    </Container>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
