import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@smooth-ui/core-sc';

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border-radius: 20px;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const VideoEmbed = ({ videoId, title }) => {
  return (
    <EmbedContainer>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}?rel=0`}
        frameBorder="0"
        allowFullScreen
        title={title}
      />
    </EmbedContainer>
  );
};

VideoEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

VideoEmbed.defaultProps = {
  title: undefined,
};

export default VideoEmbed;
